import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BabyfaceService } from 'src/app/api/babyface.service';
import { Component, OnInit } from '@angular/core';
import { Post } from 'src/app/Model/Post';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css'],
})
export class PostComponent implements OnInit {
  isCategory = 0;
  form: UntypedFormGroup;
  listPost: Post[] = [];
  page: number;
  constructor(
    private babyfaceService: BabyfaceService,
    private formBuilder: UntypedFormBuilder
  ) {}
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      type: 5,
    });
    this.getPost();
  }
  category(e) {
    this.isCategory = e;
  }
  /**
   * get review
   */
  getPost() {
    this.babyfaceService.getPost(this.form.value).subscribe((resp: any) => {
      if (resp.status === 200) {
        this.listPost = resp.data.diaries;
        this.page = resp.data.next_page;
      }
    });
  }
  getTime(e) {
    return new Date(e * 1000).toLocaleString();
  }
  convertToSlug(e, id) {
    let slug = e
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-');
    return slug + '-' + id;
  }
}
