import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BabyfaceService} from '../../api/babyface.service';
import {Frame} from '../../Model/Frame';
import {LocationService} from '../../api/location.service';
import {City} from '../../Model/City';
import {District} from '../../Model/District';
import {Item} from '../../Model/Item';
import {ActivatedRoute, Params} from "@angular/router";

@Component({
  selector: 'app-cancel-order',
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.css']
})
export class CancelOrderComponent implements OnInit {
  uploadForm: UntypedFormGroup;
  id = '';
  formattedTime = '';
  listItem = [];
  code = '';
  // tslint:disable-next-line:max-line-length
  constructor(private activatedRoute: ActivatedRoute, private formBuilder: UntypedFormBuilder, private babyfaceService: BabyfaceService, private locationService: LocationService) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      // tslint:disable-next-line:radix
      this.id = params.id;
    });
  }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      order_id: '',
      cancel_reason: ['', Validators.required]
    });
    this.getCart();
  }

  cancelOrder(): void
  {
    document.getElementById('cover-spin').style.display = 'block';
    if (this.uploadForm.valid)
    {
      this.uploadForm.value.order_id = this.id;
      this.babyfaceService.cancelOrder(this.uploadForm.value).subscribe((resp) => {
        if (resp.status === 200)
        {
          document.getElementById('cover-spin').style.display = 'none';
          document.getElementById('cancel-success').click();
          document.getElementById('success-cancel-order').style.display = 'block';
          document.getElementById('btn-return').style.display = 'flex';
          document.getElementById('btn-cancel').style.display = 'none';
        }else{
          document.getElementById('cover-spin').style.display = 'none';
          document.getElementById('cancel-false').click();
        }
      });
    }else {
      document.getElementById('cover-spin').style.display = 'none';
      document.getElementById('error-form').style.display = 'block';
      window.scroll(0, 0);
    }
  }


  // tslint:disable-next-line:typedef
  getCart() {
    // @ts-ignore
    this.babyfaceService.getCart().subscribe((resp) => {
      if (resp.status === 200)
      {
        // @ts-ignore
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0 ; i < resp.data.datas.length ; i++)
        {
          // tslint:disable-next-line:triple-equals
          if (this.id == resp.data.datas[i].id)
          {
            const time = resp.data.datas[i].created;
            const a = new Date(time * 1000);
            // const hours = date.getHours();
            // const minutes = '0' + date.getMinutes();
            // const seconds = '0' + date.getSeconds();
            const year = a.getFullYear();
            const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            const week = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'];
            const month = a.getMonth() + 1;
            const date = a.getDate();
            const hour = a.getHours();
            const min = (a.getMinutes() < 10 ? '0' : '') + a.getMinutes();
            const thu = week[a.getDay()];
            const formattedDate = date + '/' + month  + '/' + year;
            this.formattedTime = hour + ':' + min + ', ' + thu + ' ' + date + '/' + month + '/' + year;
            resp.data.datas[i].created  = formattedDate;
            this.code = resp.data.datas[i].order_code;
            this.listItem = resp.data.datas[i];
          }
        }
      }
    });
  }
  // tslint:disable-next-line:typedef
  public copOrder() {
    navigator.clipboard.writeText(this.code);
  }
}
