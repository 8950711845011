<div class="container mb-2 review" style="background-color: #FFF0F6">
  <div class="row">
    <div class="col-12" style="margin-bottom: 8px; margin-top: 8px">
      <h3 style="color: #FD799D">Review từ các hotmom</h3>
      <img src="assets/images/rate_icon.png" style="max-width: 160px">
    </div>
    <div class="col-12 review-box"  >
      <carousel [cellWidth]="300" [margin]="8" [height]="456" style="z-index: 100;" >
        <div *ngFor="let review of listReview" class="carousel-cell">
          <img src="{{review.image}}" width="300px" height="300px">
          <div class="review-title">
            <span class="review-name">
              {{review.user}}
            </span>
          </div>
          <div class="review-heart">
            <i class="fa fa-heart" aria-hidden="true"></i>
            <i class="fa fa-heart" aria-hidden="true"></i>
            <i class="fa fa-heart" aria-hidden="true"></i>
            <i class="fa fa-heart" aria-hidden="true"></i>
            <i class="fa fa-heart" aria-hidden="true"></i>
            <span>Tuyệt vời</span>
          </div>
          <div class="review-des">
            <span>{{ review.content }}</span>
          </div>
        </div>
      </carousel>
    </div>
  </div>
</div>
